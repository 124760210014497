<template>
  <div>
    <el-form ref="form" :model="condition"   label-width="80px" style="padding: 0 20px 0 20px;">
      <div style="display: flex;background-color: #ffffff;margin-top: 20px" >
        <div style="display:flex; flex: 9">
          <el-form-item label="账号">
            <el-input type="text" v-model="condition.userCode" placeholder="请输入账号" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="IP地址">
            <el-input type="text" v-model="condition.ip" placeholder="请输入IP地址" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-date-picker type="date" placeholder="选择开始时间" v-model="condition.startTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker type="date" placeholder="选择结束时间" v-model="condition.endTime" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>

          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch">查询</el-button>
          </el-form-item>
        </div>

      </div>
    </el-form>
    <div class="app-container" style="margin-top: 2px">
      <el-table
        :data="tableData"
        height="675"
        border
        stripe
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        style="width: 100%">
        <el-table-column
          fixed
          prop="userCode"
          label="用户名">
        </el-table-column>
        <el-table-column
          fixed
          prop="ip"
          label="IP地址"
          width="130">
        </el-table-column>
        <el-table-column
          fixed
          prop="createTime"
          label="创建时间"
          width="160">
        </el-table-column>
        <el-table-column
          prop="userId"
          label="用户ID"
          width="80">
        </el-table-column>

        <el-table-column
          prop="ipAddr"
          label="ipAddr"
          width="150">
        </el-table-column>
        <el-table-column
          prop="browser"
          label="浏览器"
          width="120">
        </el-table-column>

        <el-table-column
          prop="os"
          label="操作系统"
          width="255">
        </el-table-column>
        <el-table-column
          prop="userAgent"
          label="UA头"
          width="320">

        </el-table-column>
        <el-table-column
          prop="createTime"
          label="登录时间"
          width="155">
        </el-table-column>
         <el-table-column
           prop="searchKey"
           label="关键词"
           width="180">
         </el-table-column>

         <el-table-column
           prop="searchParams"
           label="请求参数"
           width="400">
         </el-table-column>
      </el-table>
    </div>
    <div class="el-footer" style="text-align: center">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import { getSubredditsByQuery } from '../../api/reddit'
import {getLogsPage} from "@/api/systemlogs";

export default {
  data() {
    return {
      loading: false,
      page: 1,
      limit: 10,
      total: 0,
      tableData:[],
      condition:{
      }
    }
  },

  created() {
    this.queryPage()
  },
  methods: {
    queryPage(page = 1){
      this.loading = true
      this.page = page
      this.$set(this.condition,'isLogin','0')
      getLogsPage(this.page,this.limit,this.condition).then(resp=>{
        this.tableData = resp.items
        this.total = resp.all_count
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryPage()
    },
    handleCurrentChange(val) {
      this.queryPage(val)
    },
    submitSearch(){
      this.queryPage()
    },
  },
}
</script>

<style lang="scss" scoped></style>
